<template>
  <div>
    <!-- <div :style="{ height: screeHeight - height + 'px',overflow: !toProduct ? 'overlay':'' }" style="background-color: #efefef;;"> -->
    <van-row
      v-if="list.length > 0"
      :style="{ height: !toProduct ? screeHeight - height : '' + 'px', overflow: !toProduct ? 'overlay' : '' }"
      style=""
    >
      <van-col span="11" v-for="(ite, index) in list" :key="index" @click="toInfo(ite.id)" class="wrap">
        <div>
          <van-image lazy-load :src="ite.logoUrl" style="height:180px;width: 100%;overflow: hidden;" />
          <div >
            <div>
              <p class="indexTitle">
                {{ ite.name }}
              </p>
              <!-- <van-row style="padding-left: 8px;padding-bottom: 2px;margin: 3px;">
                          <div style="color: #666666;font-size: 14px;padding-bottom: 10px;">
                              {{ite.provinceName}}{{ite.cityName}}{{ite.districtName}}</div>
                            <div style="color: #666666;font-size: 14px;padding-bottom: 10px;">
                              <span>营业时间：</span>{{ite.openTime}} ~ {{ite.closeTime}}</div>
                        </van-row> -->
            </div>
            <div style="padding: 5px;">
              <!-- <div>
                            <van-rate
                              :value="ite.score || 5"
                              allow-half
                              readonly
                              :size="13"
                              color="#ffd21e"
                              void-icon="star"
                              void-color="#eee"
                              style="margin-left: 2px;"
                              />
                              <span v-if="ite.score" style="font-size: 20px;color: #ffd21e;margin-left: 5px;">{{ite.score.toFixed(1)}}</span>
                              <span v-else style="font-size: 20px;color: #ffd21e;margin-left: 10px;">5.0</span>
                          </div> -->
              <!-- <div style="text-align: center;padding-top: 15px;"><span v-if="ite.delPrice || ite.originalPrice" class="delPrice">￥{{isAdd ? ite.originalPrice : ite.delPrice}}</span></div> -->
              <div style="color: #ff5345;font-weight: bold;font-size: 18px;">
                ￥{{ isAdd ? ite.salePrice : ite.minPrice
                }}<span v-if="!isAdd" style="color: #333333;font-size: 12px;margin-left: 4px;">起</span>
              </div>
            </div>
          </div>
        </div>
      </van-col>
    </van-row>
    <div v-else style="text-align: center;color: #969799;">无数据</div>
  </div>
</template>
<script>
// import { mapState } from 'vuex';
export default {
  name: "myList",
  props: {
    list: {
      type: Array,
      default: function() {
        return [];
      },
    },
    isAdd: {
      //是否可添加到购物车
      type: Boolean,
      default: false,
    },
    height: {
      //减去height
      type: Number,
      default: 0,
    },
    nonav: {
      //是否跳转详情
      type: Boolean,
      default: false,
    },
    toProduct: {
      //是否跳转门票详情
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      screeHeight: document.documentElement.clientHeight, // 屏幕高
      screenWidth: document.documentElement.clientWidth, // 屏幕宽
      productList: [],
    };
  },
  mounted() {},
  methods: {
    yuding(ite) {
      this.$http({
        method: "post",
        url: `${this.apis.buyNow}`,
        params: {
          productId: ite.id,
          isSales: 0,
        },
      }).then((res) => {
        console.log(res);
        if (res.code && res.code == 200) {
          this.$store.commit("saveOrders", res.data); //存给下单页面
          localStorage.setItem("orders", JSON.stringify(res.data));
          this.$router.push({ path: "/addOrder", query: { isAppointment: ite.isAppointment } }); //, query: this.info
        }
      });
    },
    inputNum() {
      this.updateParent();
    },
    out(index) {
      if (this.list[index].num < 1) {
        this.list[index].num = 0;
        return;
      }
      this.list[index].num--;
      this.updateParent();
    },
    add(index) {
      this.list[index].num++;
      this.updateParent();
    },
    updateParent() {
      this.$forceUpdate();
      this.$emit("getList", this.list);
    },
    toInfo(id) {
      localStorage.setItem('ordId',id)
      if (!this.nonav) {
        this.$router.push({ path: "/info", query: { id: id } });
      }
      if (this.toProduct) {
        this.$router.push({ path: "/info/productInfo", query: { id: id } });
      }
    },
    toProductInfo(id) {
      if (!this.toProduct) {
        return;
      }
      this.$router.push({ path: "/info/productInfo", query: { id: id } });
    },
  },
};
</script>
<style lang="less" scoped>
.wrap {
  margin-left: 2%;
  margin-top: 10px;
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  padding-bottom: 1px;
  box-shadow: 0 0 10px #dcdee0;
  // box-shadow: 0 0 1px #dcdee0;
  .indexTitle {
    margin: 8px 10px;
    color: #333333;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .delPrice {
    color: #bbbbbb;
    font-size: 14px;
    font-weight: 100;
    text-decoration: line-through;
  }
  .sub {
    background-color: #fd1a34;
    color: white;
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
    padding: 5px 8px;
    line-height: 20px;
    float: right;
  }
}
.out {
  border: 1px solid #ff6034;
  border-radius: 4px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  margin-right: 5px;
}
.add {
  background-color: #ff3c07;
  color: white;
  border-radius: 4px;
  width: 23px;
  height: 23px;
  line-height: 22px;
  text-align: center;
  margin-left: 5px;
}
</style>
